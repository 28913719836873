import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const actions={
	active_item(context,val){
		context.commit('ActiveItem',val)
	},
}
const mutations={
	ActiveItem(state,val){
		state.active_item=val
	}
}
const state={
	active_item:"1"
}
export default new Vuex.Store({
	actions,
	mutations,
	state,
})