<template>
	<div>
		<div>排考场</div>
		<div>
			<el-upload ref="upload" class="upload-demo" :auto-upload="false" action="#" :limit="1" accept=".xlsx"
				:http-request="uploadHttpRequest">
				<el-button slot="trigger" size="small" type="primary">选择文件</el-button>
				<el-button size="small" type="success" @click="submitUpload">上传文件</el-button>
			</el-upload>
		</div>
		<div>导出课表（统计课时）</div>
		<div>
			<el-upload ref="upload_one" class="upload-demo" :auto-upload="false" action="#" :limit="1" accept=".xlsx"
				:http-request="uploadHttpRequest_one">
				<el-button slot="trigger" size="small" type="primary">选择文件</el-button>
				<el-button size="small" type="success" @click="submitUpload_one">上传文件</el-button>
			</el-upload>
		</div>
		<div>期末考试考场考号排列</div>
		<div>
			<el-upload ref="upload_two" class="upload-demo" :auto-upload="false" action="#" :limit="1" accept=".xlsx"
				:http-request="uploadHttpRequest_two">
				<el-button slot="trigger" size="small" type="primary">选择文件</el-button>
				<el-button size="small" type="success" @click="submitUpload_two">上传文件</el-button>
			</el-upload>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'DtStudent',
		components: {

		},
		data() {
			return {
				fileList: []
			}
		},
		created() {

		},
		methods: {
			// 上传至服务器
			submitUpload() {
				this.$refs.upload.submit();
			},
			async uploadHttpRequest(param) {
				//显示上传提示
				let loading = this.$loading({
					lock: true,
					text: '上传中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				// FormData对象，添加参数只能通过append('key', value)的形式添加
				const formData = new FormData();
				// 添加文件对象
				formData.append("file", param.file)
				const config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
				this.service.post("/admin/student/student_other_upload", formData, config)
					.then(respon => {
						loading.close() //关闭上传提示
						if (respon.data == 'success') {
							this.$message({
								message: '成功上传',
								type: 'success'
							})
							this.$emit('uploaded')
							//显示安排考场提示
							loading = this.$loading({
								lock: true,
								text: '考场安排中...',
								spinner: 'el-icon-loading',
								background: 'rgba(0, 0, 0, 0.7)'
							});
							this.service.post("/admin/student/student_kc_order", {}).then(respon => {
								loading.close()
								console.log(respon.data)
								if (respon.data.status == "success") {
									//显示下载考场提示
									loading = this.$loading({
										lock: true,
										text: '下载考场中...',
										spinner: 'el-icon-loading',
										background: 'rgba(0, 0, 0, 0.7)'
									});
									this.service.get("/admin/student/student_other_export?max_kc=" + respon
										.data.max_kc + "&one_kc_number=" + respon.data.one_kc_number, {
											responseType: 'blob'
										}).then(respon => {
										loading.close()
										let start_index = respon.headers['content-disposition']
											.indexOf("=")
										let end_index = respon.headers['content-disposition']
											.indexOf(".xlsx")
										let urlencode = respon.headers['content-disposition']
											.substring(start_index + 1, end_index)
										let title = decodeURIComponent(urlencode)
										let blob = new Blob([respon.data], {
											type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
										}); // 为blob设置文件类型，这里以.xlsx为例
										let url = window.URL.createObjectURL(
										blob); // 创建一个临时的url指向blob对象
										let a = document.createElement("a");
										a.href = url;
										a.download = title;
										a.click();
										// 释放这个临时的对象url
										window.URL.revokeObjectURL(url)
									}, err => {
										if (err.response.status === 401) {
											this.$router.push({
												path: '/'
											})
										}
									})
								}
							}, err => {
								loading.close() //关闭上传提示
								if (err.response.status === 401) {
									this.$router.push({
										path: '/'
									})
								}
							})
						} else {
							this.$message({
								message: '成功失败',
								type: 'success'
							})
						}
					}, err => {
						loading.close() //关闭上传提示
						if (err.response.status === 401) {
							this.$router.push({
								path: '/'
							})
						}
					})
			},
			// 上传至服务器
			submitUpload_one() {
				this.$refs.upload_one.submit();
			},
			async uploadHttpRequest_one(param) {
				//显示上传提示
				let loading = this.$loading({
					lock: true,
					text: '上传中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				// FormData对象，添加参数只能通过append('key', value)的形式添加
				const formData = new FormData();
				// 添加文件对象
				formData.append("file", param.file)
				const config = {
					headers: {
						"Content-Type": "multipart/form-data"
					},
					responseType:'blob'
				}
				this.service.post("/admin/student/student_kb_export", formData, config)
					.then(respon => {
						loading.close() //关闭上传提示
						//console.log(respon.data)
						let start_index = respon.headers['content-disposition']
							.indexOf("=")
						let end_index = respon.headers['content-disposition']
							.indexOf(".xlsx")
						let urlencode = respon.headers['content-disposition']
							.substring(start_index + 1, end_index)
						let title = decodeURIComponent(urlencode)
						let blob = new Blob([respon.data], {
							type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
						}); // 为blob设置文件类型，这里以.xlsx为例
						let url = window.URL.createObjectURL(
						blob); // 创建一个临时的url指向blob对象
						let a = document.createElement("a");
						a.href = url;
						a.download = title;
						a.click();
						// 释放这个临时的对象url
						window.URL.revokeObjectURL(url)
					}, err => {
						loading.close() //关闭上传提示
						if (err.response.status === 401) {
							this.$router.push({
								path: '/'
							})
						}
					})
			},
			// 上传至服务器
			submitUpload_two() {
				this.$refs.upload_two.submit();
			},
			async uploadHttpRequest_two(param) {
				//显示上传提示
				let loading = this.$loading({
					lock: true,
					text: '上传中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				// FormData对象，添加参数只能通过append('key', value)的形式添加
				const formData = new FormData();
				// 添加文件对象
				formData.append("file", param.file)
				const config = {
					headers: {
						"Content-Type": "multipart/form-data"
					},
					responseType:'blob'
				}
				this.service.post("/admin/student/student_qm_kc_export", formData, config)
					.then(respon => {
						loading.close() //关闭上传提示
						//console.log(respon.data)
						let start_index = respon.headers['content-disposition']
							.indexOf("=")
						let end_index = respon.headers['content-disposition']
							.indexOf(".xlsx")
						let urlencode = respon.headers['content-disposition']
							.substring(start_index + 1, end_index)
						let title = decodeURIComponent(urlencode)
						let blob = new Blob([respon.data], {
							type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
						}); // 为blob设置文件类型，这里以.xlsx为例
						let url = window.URL.createObjectURL(
						blob); // 创建一个临时的url指向blob对象
						let a = document.createElement("a");
						a.href = url;
						a.download = title;
						a.click();
						// 释放这个临时的对象url
						window.URL.revokeObjectURL(url)
					}, err => {
						loading.close() //关闭上传提示
						if (err.response.status === 401) {
							this.$router.push({
								path: '/'
							})
						}
					})
			},
		}
	}
</script>

<style>
</style>