<template>
	<div>
		<el-header>
			<div class="title">有形德育V2.0.0</div>
			<el-dropdown @command="handleCommand">
				<span class="el-dropdown-link">
					{{school_name}}<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="a">学校中心</el-dropdown-item>
					<el-dropdown-item command="exit" divided>退出登录</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</el-header>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				school_name: ""
			}
		},
		created() {
			this.school_name = localStorage.school_name
		},
		methods: {
			handleCommand(command) {
				if(command=="exit"){
					localStorage.token = "";
					this.$router.push({
						path: "/"
					})
				}
			},
		}
	}
</script>

<style lang="scss">
	.el-header {
		line-height: 60px;
		font-size: 30px;
		background-color: #409eff;
		display: flex;
		justify-content: space-between;
		.title {
			color: white;
		}
		.el-dropdown{
			.el-dropdown-link{
				color: white;
			}
		}
	}
</style>