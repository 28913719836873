<template>
	<div>
		<div class="flex">
			<div class="flex_item">
				<el-input style="width: 200px;" v-model="name" placeholder="输入姓名搜索"></el-input>
				<el-button type="primary" @click="search">搜索</el-button>
			</div>
			<div class="flex_item">
				<el-button type="primary" @click="add">新增</el-button>
				<el-button type="success" @click="edit">编辑</el-button>
				<el-button type="danger" @click="delete_all">删除</el-button>
			</div>
		</div>
		<TableList :tableHeader="tableHeader" :tableData="tableData" @selectTableRows="selectTableRows"></TableList>
		<PageNation :pageTotle="page_totle" @currentPage='currentPage'></PageNation>
		<UploadFile downloadUrl="/admin/dormitory_info/excel_export" uploadUrl="/admin/dormitory_info/insert_excel">
		</UploadFile>
	</div>
</template>

<script>
	import UploadFile from '../components/UploadFile.vue'
	import PageNation from '../components/PageNation.vue'
	import TableList from '../components/TableList.vue'
	import {
		getData
	} from "@/utils/table" //,edit
	export default {
		name: 'DtDormitory',
		components: {
			UploadFile,
			PageNation,
			TableList
		},
		data() {
			return {
				tableHeader: [
					{
						fixed: false,
						prop: 'id',
						label: '宿舍楼编号',
						width: 100
					},
					{
						fixed: false,
						prop: 'school_name',
						label: '学校',
						width: null
					},
					{
						fixed: false,
						prop: 'campus_name',
						label: '分校',
						width: null
					},
					{
						fixed: false,
						prop: 'dormitory_name',
						label: '宿舍楼',
						width: null
					},
				],
				tableData: [],
				page_totle: 1,
				loading: false,
			}
		},
		created() {
			getData(this, "/admin/dormitory_info/index", {
				page: 1
			}, data => {
				this.page_totle = data[0]
				this.tableData = data[1]
			})
		},
		methods: {
			//翻页处理
			currentPage(val) {
				getData(this, "/admin/dormitory_info/index", {
					page: val
				}, data => {
					this.page_totle = data[0]
					this.tableData = data[1]
				})
			}
		}
	}
</script>

<style>
</style>