<template>
	<div>
		<FormDialog :formObject="formObject" :formInfo="formInfo"></FormDialog>
		<div class="flex">
			<div class="flex_item">
				<el-input style="width: 200px;" v-model="name" placeholder="输入姓名搜索"></el-input>
				<el-button type="primary" @click="search">搜索</el-button>
			</div>
			<div class="flex_item">
				<el-button type="primary" @click="add">新增</el-button>
				<el-button type="success" @click="edit">编辑</el-button>
				<el-button type="danger" @click="delete_all">删除</el-button>
				<el-button type="danger" @click="updateFace">更新人脸</el-button>
			</div>
		</div>
		<TableList :tableHeader="tableHeader" :tableData="tableData" @selectTableRows="selectTableRows"></TableList>
		<PageNation :pageTotle="page_totle" @currentPage='currentPage'></PageNation>
		<UploadFile downloadUrl="/admin/student/student_excel_export" uploadUrl="/admin/student/student_insert_excel">
		</UploadFile>
	</div>
</template>

<script>
	import UploadFile from '../../components/UploadFile.vue'
	import PageNation from '../../components/PageNation.vue'
	import TableList from '../../components/TableList.vue'
	import FormDialog from '../../components/FormDialog.vue'
	import {
		getData,
		del
	} from "@/utils/table" //,edit
	export default {
		name: 'DtStudent',
		components: {
			UploadFile,
			PageNation,
			TableList,
			FormDialog
		},
		data() {
			return {
				formObject: {},
				formInfo: {},
				formLabelWidth: '110px',
				tableHeader: [{
						fixed: true,
						prop: 'school_student_code',
						label: '考号',
						width: 100
					},
					{
						fixed: true,
						prop: 'name',
						label: '学生姓名',
						width: null
					},
					{
						fixed: false,
						prop: 'school_name',
						label: '学校',
						width: 200
					},
					{
						fixed: false,
						prop: 'campus_name',
						label: '分校',
						width: null
					},
					{
						fixed: false,
						prop: 'period',
						label: '学级',
						width: 100
					},
					{
						fixed: false,
						prop: 'level_name',
						label: '学部',
						width: 100
					},
					{
						fixed: false,
						prop: 'grade_name',
						label: '年级',
						width: null
					},
					{
						fixed: false,
						prop: 'class_number',
						label: '班级',
						width: 100
					},
					{
						fixed: false,
						prop: 'student_status_id',
						label: '状态',
						width: 100
					},
					{
						fixed: false,
						prop: 'subject_group',
						label: '科目组',
						width: 100
					},
					{
						fixed: false,
						prop: 'foreign_language',
						label: '外语',
						width: 100
					},
					{
						fixed: false,
						prop: 'japanese_fund',
						label: '日语基础',
						width: 100
					},
					{
						fixed: false,
						prop: 'evaluate',
						label: '参评',
						width: 100
					},
					{
						fixed: false,
						prop: 'sex',
						label: '性别',
						width: 100
					},
					{
						fixed: false,
						prop: 'nation',
						label: '民族',
						width: 100
					},
					{
						fixed: false,
						prop: 'id_card',
						label: '身份证',
						width: 200
					},
					{
						fixed: false,
						prop: 'province',
						label: '省',
						width: 100
					}, {
						fixed: false,
						prop: 'city',
						label: '市',
						width: 100
					},
					{
						fixed: false,
						prop: 'district',
						label: '区',
						width: 100
					},
					{
						fixed: false,
						prop: 'village',
						label: '乡镇',
						width: 100
					},
					{
						fixed: false,
						prop: 'liaison_man',
						label: '紧急联系人',
						width: 100
					},
					{
						fixed: false,
						prop: 'liaison_man_tele',
						label: '紧急联系人电话',
						width: 200
					},
				],
				tableData: [],
				name: "",
				page_totle: 1,
			}
		},
		created() {
			getData(this, "/admin/student/index", {
				page: 1
			}, (data) => {
				console.log(data)
				this.page_totle = data[0]
				this.tableData = data[1]
				for (var i = 0; i < this.tableData.length; i++) {
					this.tableData[i].school_name = this.tableData[i].class_info.school_name
					this.tableData[i].campus_name = this.tableData[i].class_info.campus_name
					this.tableData[i].level_name = this.tableData[i].class_info.level_name
					this.tableData[i].grade_name = this.tableData[i].class_info.grade_name
					this.tableData[i].period = this.tableData[i].class_info.period
					this.tableData[i].class_number = this.tableData[i].class_info.class_number
				}
			})
			getData(this, "/admin/teacher/school", {
				page: 1
			}, data => {
				this.school_class_options=[]
				// console.log(data)
				//遍历分校
				for(var i=0; i<data.campus.length;i++){
					this.school_class_options.push({value:data.campus[i].id,label:data.campus[i].campus_name,children:[]})
					//遍历分校下面的学部
					for(var j=0;j<data.campus[i].level.length;j++){
						this.school_class_options[i].children.push({value:data.campus[i].level[j].school_level_id,label:data.campus[i].level[j].level_name,children:[]})
						//遍历学部下面的年级
						for(var k=0;k<data.campus[i].level[j].grade.length;k++){
							this.school_class_options[i].children[j].children.push({value:data.campus[i].level[j].grade[k].school_grade_id,label:data.campus[i].level[j].grade[k].grade_name,children:[]})
							//遍历年级下面的班级
							for(var m=0;m<data.campus[i].level[j].grade[k].classes.length;m++){
								this.school_class_options[i].children[j].children[k].children.push({value:data.campus[i].level[j].grade[k].classes[m].id,label:data.campus[i].level[j].grade[k].classes[m].class_number+'班'})
							}
						}
					}
				}
				// console.log(this.school_class_options)
			})
		},
		methods: {
			search() {
				if (this.name == "") {
					this.$message({
						message: '请输入学生姓名',
						type: 'warning'
					})
				} else {
					getData(this, '/admin/student/search', {
						name: this.name,
						page:1
					}, (data) => {
						this.page_totle = data[0]
						this.tableData = data[1]
						for (var i = 0; i < this.tableData.length; i++) {
							this.tableData[i].school_name = this.tableData[i].class_info.school_name
							this.tableData[i].campus_name = this.tableData[i].class_info.campus_name
							this.tableData[i].level_name = this.tableData[i].class_info.level_name
							this.tableData[i].grade_name = this.tableData[i].class_info.grade_name
						}
					})
				}
			},
			//添加数据
			add() {
				this.formInfo = {
					title: '增加学生',
					visible: true,
					url: '/admin/student/add',
					formItems: [{
							label: "学生姓名",
							key: "name",
							type: "input"
						},
						{
							label: "选择班级",
							key: "school_class_id_3",
							type: 'cascader',
							options: this.school_class_options
						}
					],
					rules: {
						name: [{
								required: true,
								message: '姓名必填',
								trigger: 'blur'
							},
						],
					}
				}
			},
			edit() {
				if (this.select_val.length < 1) {
					this.$message({
						message: '请选择一条数据！',
						type: 'warning'
					})
				} else {
					if (this.select_val.length == 1) {
						this.edit_student = this.select_val[0]
						var arr = [this.edit_student.level_id, this.edit_student.grade_id, this.edit_student.class_id]
						this.level_grade_class_value = arr
						this.editVisible = true
					} else {
						this.$message({
							message: '只能选择一条数据！',
							type: 'warning'
						})
					}
				}
			},
			post_edit() {
				this.axios.post(this.host + "/index.php/admin/student/edit", this.edit_student).then(respon => {
					if (respon.data === 'success') {
						this.$message({
							message: '修改成功',
							type: 'success'
						})
						this.editVisible = false;
					} else {
						this.$message({
							message: '修改失败',
							type: 'warning'
						})
					}
				}, err => {
					if (err.response.data.code === 401) {
						this.$router.push({
							name: 'DtLogin'
						})
					}
				});
			},
			//删除数据
			delete_all() {
				if (this.tableRows.length == 0) {
					this.$message({
						message: '选择数据',
						type: 'warning'
					})
				} else {
					let arr = []
					let ids = []
					for (var i = 0; i < this.tableRows.length; i++) {
						arr.push(this.tableRows[i].name)
						ids.push(this.tableRows[i].id)
					}
					del(this, "/admin/student/delete", arr.toString(), {
						ids: ids.join(",")
					}, () => {
						for (var k = 0; k < ids.length; k++) {
							for (var i = 0; i < this.tableData.length; i++) {
								if (this.tableData[i].id == ids[k]) {
									this.tableData.splice(i, 1);
								}
							}
						}
					})
				}
			},
			//表格选择处理，选出每条数据的编码
			selectTableRows(tableRows) {
				this.tableRows = tableRows;
			},
			//翻页处理
			currentPage(val) {
				getData(this, "/admin/student/index", {
					page: val
				}, (data) => {
					this.page_totle = data[0]
					this.tableData = data[1]
					for (var i = 0; i < this.tableData.length; i++) {
						this.tableData[i].school_name = this.tableData[i].class_info.school_name
						this.tableData[i].campus_name = this.tableData[i].class_info.campus_name
						this.tableData[i].level_name = this.tableData[i].class_info.level_name
						this.tableData[i].grade_name = this.tableData[i].class_info.grade_name
						this.tableData[i].period = this.tableData[i].class_info.period
						this.tableData[i].class_number = this.tableData[i].class_info.class_number
					}
				})
			},
			handleChange(value) {
				this.edit_student.level_id = value[0]
				this.edit_student.grade_id = value[1]
				this.edit_student.class_id = value[2]
			},
			updateFace(){
				console.log("开始更新。。。")
				this.service.post("/admin/student/update_face", {}).then(respon => {
					console.log(respon.data)
				}, err => {
					if (err.response.status === 401) {
						this.$router.push({
							path: '/'
						})
					}
				})
				
			}
		}
	}
</script>

<style>
</style>