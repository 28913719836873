<template>
	<div>
		<div class="flex">
			<div class="flex_item">
				<el-input style="width: 200px;" v-model="name" placeholder="输入姓名搜索"></el-input>
				<el-button type="primary" @click="search">搜索</el-button>
			</div>
			<div class="flex_item">
				<el-button type="primary" @click="add">新增</el-button>
				<el-button type="success" @click="edit">编辑</el-button>
				<el-button type="danger" @click="delete_all">删除</el-button>
			</div>
		</div>
		<TableList :tableHeader="tableHeader" :tableData="tableData" @selectTableRows="selectTableRows"></TableList>
		<PageNation :pageTotle="page_totle" @currentPage='currentPage'></PageNation>
		<UploadFile downloadUrl="/admin/dormitoryroombedinfo/excel_export" uploadUrl="/admin/dormitoryroombedinfo/insert_excel">
		</UploadFile>
	</div>
</template>

<script>
	import UploadFile from '../components/UploadFile.vue'
	import PageNation from '../components/PageNation.vue'
	import TableList from '../components/TableList.vue'
	import {
		getData,
		del
	} from "@/utils/table" //,edit
	export default {
		name: 'DtDormitory',
		components: {
			UploadFile,
			PageNation,
			TableList
		},
		data() {
			return {
				uploadVisible: false,
				uploadTitle: "",
				uploadUrl: "",
				downloadUrl: "",
				dormitory_lists: [],
				dormitory_room_lists: [],
				campuses: [], //分校
				activeName: "",
				addVisible: false,
				editVisible: false,
				formLabelWidth: '110px',
				tableHeader: [
					{
						fixed: false,
						prop: 'id',
						label: '床位编号',
						width: 100
					},
					{
						fixed: false,
						prop: 'school_name',
						label: '学校',
						width: null
					},
					{
						fixed: false,
						prop: 'campus_name',
						label: '分校',
						width: null
					},
					{
						fixed: false,
						prop: 'dormitory_name',
						label: '宿舍楼',
						width: null
					},
					{
						fixed: false,
						prop: 'dormitory_room_name',
						label: '房间号',
						width: null
					},
					{
						fixed: false,
						prop: 'dormitory_room_bed_name',
						label: '床位',
						width: null
					},
					{
						fixed: false,
						prop: 'name',
						label: '学生',
						width: null
					}
				],
				tableData: [],
				loadingTableData: true,
				name: "",
				page_totle: 1,
				codes: [],
				select_val: [],
				loading: false,
				fileList: [],
				campus_id: '',
			}
		},
		created() {
			getData(this, "/admin/dormitoryroombedinfo/index", {
				page: 1
			}, data => {
				this.page_totle = data[0]
				this.tableData = data[1]
			})
		},
		methods: {
			//删除数据
			delete_all() {
				if (this.tableRows.length == 0) {
					this.$message({
						message: '选择数据',
						type: 'warning'
					})
				} else {
					let arr = []
					let ids = []
					for (var i = 0; i < this.tableRows.length; i++) {
						arr.push(this.tableRows[i].dormitory_name + this.tableRows[i].dormitory_room_name+ this.tableRows[i].dormitory_room_bed_name)
						ids.push(this.tableRows[i].id)
					}
					del(this, "/admin/dormitoryroominfo/delete", arr.toString(), {
						ids: ids.join(",")
					}, () => {
						for (var k = 0; k < ids.length; k++) {
							for (var i = 0; i < this.tableData.length; i++) {
								if (this.tableData[i].id == ids[k]) {
									this.tableData.splice(i, 1);
								}
							}
						}
					})
				}
			},
			//表格选择处理，选出每条数据的编码
			selectTableRows(tableRows) {
				this.tableRows = tableRows;
			},
			//翻页处理
			currentPage(val) {
				getData(this, "/admin/dormitoryroombedinfo/index", {
					page: val
				}, data => {
					this.page_totle = data[0]
					this.tableData = data[1]
				})
			}
		}
	}
</script>

<style>
</style>