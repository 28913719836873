<template>
	<div>
		<DtHeader></DtHeader>
		<el-container class="content">
			<DtMenu></DtMenu>
			<el-container>
				<el-main>
					<DtBreadcrumb></DtBreadcrumb>
					<router-view></router-view>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<script>
	import DtHeader from '../components/DtHeader.vue'
	import DtMenu from '../components/DtMenu.vue'
	import DtBreadcrumb from '../components/DtBreadcrumb.vue'
	// import * as echarts from 'echarts';
	export default {
		name: 'DtIndex',
		components: {
			DtHeader,
			DtMenu,
			DtBreadcrumb
		},
		data() {
			return {
				
			}
		}
	}
</script>

<style lang="scss">
	.content {
		position: absolute;
		top: 60px;
		width: 100%;
		height: 100%;
		bottom: 0;
	}
</style>