import { render, staticRenderFns } from "./DtMenu.vue?vue&type=template&id=841e447c&scoped=true&"
import script from "./DtMenu.vue?vue&type=script&lang=js&"
export * from "./DtMenu.vue?vue&type=script&lang=js&"
import style0 from "./DtMenu.vue?vue&type=style&index=0&id=841e447c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "841e447c",
  null
  
)

export default component.exports