<template>
	<div id="app" >
		<router-view></router-view>
	</div>
</template>
<script>
	export default {
		name: 'App',
		components:{
		},
	}
</script>

<style>
	body {
		margin: 0;
		padding: 0;
		border: 0
	}
</style>
