<template>
	<div style="margin-top: 20px;">
		<el-table :data="tableData" border stripe style="width: 100%" height="500"
			@selection-change="handleSelectionChange">
			<el-table-column type="selection" width="55">
			</el-table-column>
			<el-table-column :fixed="item.fixed" :prop="item.prop" :label="item.label" :width="item.width" v-for="(item,index) in tableHeader" :key="index">
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	export default {
		props: ['tableHeader','tableData'],
		data() {
			return {
				ids:[]
			}
		},
		methods: {
			//表格选择处理
			handleSelectionChange(val) {
				this.$emit('selectTableRows',val)
			},
		}
	}
</script>

<style>
</style>