import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
Vue.prototype.axios = axios
//引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入路由
import VueRouter from 'vue-router'
//引入路由器
import router from './router/index.js'
import store from './store/index.js'
import service from './service.js'
Vue.prototype.service = service
Vue.config.productionTip = false
//使用路由
Vue.use(VueRouter)
//使用ElementUI
Vue.use(ElementUI)
//请求头设置
axios.interceptors.request.use(function(config){
	config.headers.Authorization =localStorage.token
	return config
})
Vue.prototype.screenHeigt=window.screen.height
Vue.prototype.screenWidth=window.screen.width
new Vue({
	store,
	render: h => h(App),
	router,
}).$mount('#app')