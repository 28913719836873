<template>
	<div>
		<FormDialog :formObject="formObject" :formInfo="formInfo"></FormDialog>
		<div class="flex">
			<div class="flex_item">
				<el-input style="width: 200px;" v-model="id" placeholder="输入编号"></el-input>
				<el-button type="primary" @click="search">搜索</el-button>
			</div>
			<div class="flex_item">
				<el-button type="primary" @click="add">新增</el-button>
				<el-button type="success" @click="edit">编辑</el-button>
				<el-button type="danger" @click="delete_all">删除</el-button>
			</div>
		</div>
		<TableList :tableHeader="tableHeader" :tableData="tableData" @selectTableRows="selectTableRows"></TableList>
		<PageNation :pageTotle="page_totle" @currentPage='currentPage'></PageNation>
	</div>
</template>

<script>
	import PageNation from '../components/PageNation.vue'
	import TableList from '../components/TableList.vue'
	import FormDialog from '../components/FormDialog.vue'
	import {getData,del} from "@/utils/table"//,edit,add,uploadFile,downloadFile,del
	export default {
		name: 'DtStudentScore',
		components: {
			PageNation,
			TableList,
			FormDialog
		},
		data() {
			return {
				formLabelWidth: '110px',
				tableHeader: [{
						fixed: true,
						prop: 'id',
						label: '编号',
						width: 50
					},
					{
						fixed: false,
						prop: 'grade_name',
						label: '年级',
						width: 150
					},
					{
						fixed: false,
						prop: 'class_number',
						label: '班级',
						width: 50
					},
					{
						fixed: false,
						prop: 'name',
						label: '姓名',
						width: 100
					},
					{
						fixed: false,
						prop: 'category',
						label: '类型',
						width: 100
					},
					{
						fixed: false,
						prop: 'content',
						label: '具体内筒',
						width: 250
					},
					{
						fixed: false,
						prop: 'score',
						label: '分值',
						width: null
					},
					{
						fixed: false,
						prop: 'market',
						label: '备注',
						width: null
					},
					{
						fixed: false,
						prop: 'upload_person',
						label: '上传人',
						width: 100
					},
					{
						fixed: false,
						prop: 'upload_person_tele',
						label: '上传人电话',
						width: 150
					},
					{
						fixed: false,
						prop: 'create_time',
						label: '上传时间',
						width: 200
					}
				],
				tableData: [],
				id: "",
				page_totle: 1,
				ids: [],
				searchData:false,
			}
		},
		created(){
			getData(this, "/admin/studentscore/index", {
				page: 1
			}, data => {
				this.page_totle = data[0]
				this.tableData = data[1]
			})
		},
		methods: {
			//搜索数据
			search() {
				if (this.id === "") {
					this.$message({
						message: '请输入编号',
						type: 'warning'
					})
				} else {
					this.searchData = true
					getData(this, '/admin/studentscore/search', {
						id: this.id,
						page: 1
					}, data => {
						this.page_totle = data[0]
						this.tableData = data[1]
					})
				}
			},
			//删除数据
			delete_all() {
				if (this.tableRows.length == 0) {
					this.$message({
						message: '选择数据',
						type: 'warning'
					})
				} else {
					let arr = []
					let ids = []
					for (var i = 0; i < this.tableRows.length; i++) {
						arr.push(this.tableRows[i].name)
						ids.push(this.tableRows[i].id)
					}
					del(this, "/admin/studentscore/delete", arr.toString(), {
						ids: ids.join(",")
					}, () => {
						for (var k = 0; k < ids.length; k++) {
							for (var i = 0; i < this.tableData.length; i++) {
								if (this.tableData[i].id == ids[k]) {
									this.tableData.splice(i, 1);
								}
							}
						}
					})
				}
			},
			//表格选择处理，选出每条数据的编码
			selectTableRows(tableRows) {
				this.tableRows = tableRows;
			},
			//翻页处理
			currentPage(val) {
				if (this.searchData) {
					getData(this, '/admin/studentscore/search', {
						id: this.id,
						page: 1
					}, data => {
						this.page_totle = data[0]
						this.tableData = data[1]
					})
				} else {
					getData(this, "/admin/studentscore/index", {
						page: val
					}, data => {
						this.page_totle = data[0]
						this.tableData = data[1]
					})
				}
			
			}
		}
	}
</script>

<style>
</style>
