<template>
	<div>
		<div class="login">
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<span>有形德育V2.0.0</span>
				</div>
				<el-form ref="form" :model="form" label-width="80px">
					<el-form-item label="用户名">
						<el-input v-model="form.name"></el-input>
					</el-form-item>
					<el-form-item label="密码">
						<el-input v-model="form.password" type="password"></el-input>
					</el-form-item>
					<el-form-item label="验证码">
						<el-input v-model="form.captcha" @keyup.enter.native="onSubmit"></el-input>
					</el-form-item>
					<el-form-item>
						<img class="image" :src="camptcha" @click="update_capcha" />
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">立即登录</el-button>
					</el-form-item>
				</el-form>
			</el-card>
		</div>
		<div
			style="background-color: white;position: fixed;left: 0px;bottom: 0px;width: 100%;line-height: 40px;text-align: center;">
			黔ICP备2021006347号-1
		</div>
		<img :style="{height:screenHeigt+'px',width:screenWidth+'px'}"
			src="../assets/digitaleducation_background.png" />
	</div>
</template>

<script>
	import token from "@/utils/token.js"
	export default {
		name: 'DtLogin',
		data() {
			return {
				form: {
					name: "",
					password: "",
					captcha: '',
					uniqid: ""
				},
				camptcha: ""
			}
		},
		mounted() {
			this.service.get("/admin/admin/get_captcha").then(respon => {
				this.camptcha = respon.data.content
				this.form.uniqid = respon.data.uniqid
			},error=>{
				alert(error.message)
			})
		},
		methods: {
			update_capcha() {
				this.service.get("/admin/admin/get_captcha").then(respon => {
					this.camptcha = respon.data.content
					this.form.uniqid = respon.data.uniqid
				}, err => {
					alert(err.message)
				})
			},
			onSubmit() {
				this.service.post('/admin/admin/dt_login', this.form).then(respon => {
						if (respon.data[0] === 'success') {
							token.setToken('token', respon.data[1])
							localStorage.school_name = respon.data[2]
							this.$router.push({
								path: '/dtindex'
							})
						} else {
							if (respon.data[0] === 'captcha_err') {
								this.$message('验证码错误')
								this.update_capcha()
							} else {
								if (respon.data[0] === 'name_err') {
									this.$message('用户名不存在')
									this.update_capcha()
								} else {
									this.$message('密码错误')
									this.update_capcha()
								}
							}
						}
					}, err => {
						alert(err.message);
					}
				)
			}
		},
	}
</script>

<style lang="scss">
	.login {
		position: absolute;
		width: 100%;
		height: 100%;

		.el-card {
			width: 450px;
			margin: 200px auto;

			.el-card__header {
				font-size: 35px;
				text-align: center;
			}

			.el-button {
				width: 100%;
			}
		}
	}
</style>