<template>
	<div class="flex_right">
		<div class="flex_item1">
			<el-upload ref="upload" class="upload-demo" :auto-upload="false" action="#" :limit="1" :file-list="fileList"
				accept=".xlsx" :http-request="uploadHttpRequest">
				<el-button slot="trigger" size="small" type="primary">选择文件</el-button>
				<el-button size="small" type="success" @click="submitUpload">上传文件</el-button>
			</el-upload>
		</div>
		<div class="flex_item">
			<el-button v-loading="loading" size="small" type="primary" @click="download" style="width: 200px;">下载文件模板
			</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['downloadUrl', 'uploadUrl'],
		data() {
			return {
				loading: false,
				fileList: [],
			}
		},
		methods: {
			download() {
				this.loading = true
				this.service.get(this.downloadUrl, {
					responseType: 'blob'
				}).then(respon => {
					let start_index = respon.headers['content-disposition'].indexOf("=")
					let end_index = respon.headers['content-disposition'].indexOf(".xlsx")
					let urlencode = respon.headers['content-disposition'].substring(start_index + 1, end_index)
					let title = decodeURIComponent(urlencode)
					let blob = new Blob([respon.data], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
					}); // 为blob设置文件类型，这里以.xlsx为例
					let url = window.URL.createObjectURL(blob); // 创建一个临时的url指向blob对象
					let a = document.createElement("a");
					a.href = url;
					a.download = title;
					a.click();
					// 释放这个临时的对象url
					window.URL.revokeObjectURL(url);
					this.loading = false
				}, err => {
					if (err.response.status === 401) {
						this.$router.push({
							path: '/'
						})
					}
				})
			},
			// 上传至服务器
			submitUpload() {
				this.$refs.upload.submit();
			},
			async uploadHttpRequest(param) {
				//显示上传提示
				const loading = this.$loading({
					lock: true,
					text: '上传中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				// FormData对象，添加参数只能通过append('key', value)的形式添加
				const formData = new FormData();
				// 添加文件对象
				formData.append("file", param.file)
				const config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
				this.service.post(this.uploadUrl, formData, config)
					.then(respon => {
						console.log(respon.data)
						loading.close() //关闭上传提示
						if (respon.data['status'] == 'success') {
							this.$message({
								message: '成功上传' + respon.data['uploadTotal'] + '条数据',
								type: 'success'
							})
							this.$emit('uploaded')
						} else {
							if (respon.data['status'] == 'error') {
								this.$confirm("表格第" + respon.data['errorRow'] + "行出错，错误信息：" + respon.data[
										'errorMessage'],
									'提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										type: 'warning'
									}).then(() => {}).catch(() => {});
							}
						}
					}, err => {
						loading.close() //关闭上传提示
						if (err.response.status === 401) {
							this.$router.push({
								path: '/'
							})
						}
					})
			},
		}
	}
</script>

<style>
	.flex_right {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
	.flex_item1 {
		margin-right: 10px;
	}
</style>