<template>
	<div>
		<FormDialog :formObject="formObject" :formInfo="formInfo"></FormDialog>
		<div class="flex">
			<div class="flex_item">
				<el-input style="width: 200px;" v-model="name" placeholder="输入姓名搜索"></el-input>
				<el-button type="primary" @click="search">搜索</el-button>
			</div>
			<div class="flex_item">
				<el-button type="primary" @click="add_modle">新增</el-button>
				<el-button type="success" @click="edit">编辑</el-button>
				<el-button type="danger" @click="delete_all">删除</el-button>
			</div>
		</div>
		<TableList :tableHeader="tableHeader" :tableData="tableData" @selectTableRows="selectTableRows"></TableList>
		<PageNation :pageTotle="page_totle" @currentPage='currentPage'></PageNation>
		<div class="row">
			<el-button  size="small" type="primary" @click="download"
				style="width: 200px;">下载数据
			</el-button>
		</div>
	</div>
</template>

<script>
	import PageNation from '../components/PageNation.vue'
	import TableList from '../components/TableList.vue'
	import FormDialog from '../components/FormDialog.vue'
	import {
		getData,
		del
	} from "@/utils/table" //,edit
	export default {
		name: 'DtNotes',
		components: {
			PageNation,
			TableList,
			FormDialog
		},
		data() {
			return {
				formObject: {},
				formInfo: {},
				tableHeader: [
					{
						fixed: true,
						prop: 'id',
						label: '假条编号',
						width: 100
					},
					{
						fixed: true,
						prop: 'name',
						label: '学生姓名',
						width: null
					},
					{
						fixed: false,
						prop: 'level_name',
						label: '学部',
						width: 100
					},
					{
						fixed: false,
						prop: 'grade_name',
						label: '年级',
						width: 150
					},
					{
						fixed: false,
						prop: 'class_number',
						label: '班级',
						width: 100
					},
					{
						fixed: false,
						prop: 'note_type',
						label: '请假类型',
						width: 100
					},
					{
						fixed: false,
						prop: 'type',
						label: '类别',
						width: 100
					},
					{
						fixed: false,
						prop: 'reason',
						label: '具体原因',
						width: 100
					},
					{
						fixed: false,
						prop: 'headermaster',
						label: '班主任',
						width: 100
					},
					{
						fixed: false,
						prop: 'headermaster_tele',
						label: '班主任电话',
						width: 150
					},
					{
						fixed: false,
						prop: 'guardian',
						label: '监护人',
						width: 150
					},
					{
						fixed: false,
						prop: 'guardian_tele',
						label: '监护人电话',
						width: 150
					},
					{
						fixed: false,
						prop: 'create_time',
						label: '开假条时间',
						width: 150
					},
					{
						fixed: false,
						prop: 'release_staff',
						label: '离校放行人',
						width: 150
					},
					{
						fixed: false,
						prop: 'leave_time',
						label: '离校时间',
						width: 150
					},
					{
						fixed: false,
						prop: 'gatekeeper',
						label: '返校放行人',
						width: 150
					},
					{
						fixed: false,
						prop: 'comeback_time',
						label: '返校时间',
						width: 150
					},
					{
						fixed: false,
						prop: 'return_time',
						label: '销假时间',
						width: 150
					}
				],
				tableData: [],
				name: "",
				page_totle: 1,
				ids: [],
			}
		},
		created(){
			getData(this, "/admin/notes/index", {
				page: 1
			},(data) => {
				this.page_totle = data[0]
				this.tableData = data[1]
				for(var i=0;i<this.tableData.length;i++){
					this.tableData[i].school_name=this.tableData[i].class_info.school_name
					this.tableData[i].campus_name=this.tableData[i].class_info.campus_name
					this.tableData[i].level_name=this.tableData[i].class_info.level_name
					this.tableData[i].grade_name=this.tableData[i].class_info.grade_name
				}
			})
		},
		methods: {
			search() {
				if (this.name === "") {
					this.$message({
						message: '请输入姓名',
						type: 'warning'
					})
				} else {
					console.log(11)
				}
			},
			download(){
				this.formInfo = {
					title: '选择时间',
					type:'download',
					visible: true,
					url: '/admin/notes/excel_export',
					formItems: [{
							label: "开始时间",
							key: "start_time",
							type: "date"
						},
						{
							label: "截止时间",
							key: "end_time",
							type: 'date'
						}
					],
					rules: {
						start_time: [{
								required: true,
								message: '开始时间必填',
								trigger: 'blur'
							},
						],
						end_time: [{
								required: true,
								message: '截止时间必填',
								trigger: 'blur'
							},
						]
					}
				}
			},
			//表格选择处理，选出每条数据的编码
			selectTableRows(tableRows) {
				this.tableRows = tableRows;
			},
			//翻页处理
			currentPage(val) {
				getData(this, "/admin/notes/index", {
					page: val
				}, (data) => {
					this.page_totle = data[0]
					this.tableData = data[1]
					for(var i=0;i<this.tableData.length;i++){
						this.tableData[i].school_name=this.tableData[i].class_info.school_name
						this.tableData[i].campus_name=this.tableData[i].class_info.campus_name
						this.tableData[i].level_name=this.tableData[i].class_info.level_name
						this.tableData[i].grade_name=this.tableData[i].class_info.grade_name
					}
				})
			},
			//删除数据
			delete_all() {
				if (this.tableRows.length == 0) {
					this.$message({
						message: '选择数据',
						type: 'warning'
					})
				} else {
					let arr = []
					let ids = []
					for (var i = 0; i < this.tableRows.length; i++) {
						arr.push(this.tableRows[i].name)
						ids.push(this.tableRows[i].id)
					}
					del(this, "/admin/notes/delete", arr.toString(), {
						ids: ids.join(",")
					}, () => {
						for (var k = 0; k < ids.length; k++) {
							for (var i = 0; i < this.tableData.length; i++) {
								if (this.tableData[i].id == ids[k]) {
									this.tableData.splice(i, 1);
								}
							}
						}
					})
				}
			},
		}
	}
</script>

<style>
</style>
