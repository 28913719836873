import VueRouter from 'vue-router'
import DtLogin from '../pages/DtLogin'
import DtCountdown from '../pages/DtCountdown'
import DtIndex from '../pages/DtIndex'
import DtTeacher from '../pages/DtTeacher'
import DtTeacherClass from '../pages/DtTeacherClass'
import DtTeacherPosition from '../pages/DtTeacherPosition'
import DtTeacherDepartment from '../pages/DtTeacherDepartment'
import DtMoralQuantitation from '../pages/DtMoralQuantitation'
import DtAssessmentRecord from '../pages/DtAssessmentRecord'
import DtStudent from '../pages/student/DtStudent'
import DtStudentQuantitation from '../pages/DtStudentQuantitation'
import DtStudentScore from '../pages/DtStudentScore'
import DtStudentOther from '../pages/student/DtStudentOther'
import DtNotes from '../pages/DtNotes'
import DtDormitoryInfo from '../pages/DtDormitoryInfo'
import DtDormitoryRoomInfo from '../pages/DtDormitoryRoomInfo'
import DtDormitoryRoomBedInfo from '../pages/DtDormitoryRoomBedInfo'
import DtClass from '../pages/DtClass'
import DtPosition from '../pages/DtPosition'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
	routes: [{
			path: '/',
			name: '登录页面',
			hidden: true,
			component: DtLogin
		},
		{
			path: '/dtcountdown',
			name: '倒计时页面',
			hidden: true,
			component: DtCountdown
		},
		{
			path: '/dtindex',
			name: '教师管理',
			component: DtIndex,
			iconClass: "el-icon-user-solid",
			redirect: "/dtteacher",
			children: [{
					path: '/dtteacher',
					name: '教师列表',
					component: DtTeacher
				},
				{
					path: '/dtteacherclass',
					name: '教师班级',
					component: DtTeacherClass
				},
				{
					path: '/dtteacherpostion',
					name: '教师职位',
					component: DtTeacherPosition
				},
				{
					path: '/dtteacherdepartment',
					name: '教师部门',
					component: DtTeacherDepartment
				}
			]
		},
		{
			path: '/dtindex',
			name: '学生管理',
			component: DtIndex,
			iconClass: "el-icon-user",
			children: [{
					path: '/dtstudent',
					name: '学生列表',
					component: DtStudent
				},
				{
					path: '/dtnotes',
					name: '假条',
					component: DtNotes
				},
				{
					path: '/dtstudentscore',
					name: '违纪记录',
					component: DtStudentScore
				},
				{
					path: '/dtstudentquantitation',
					name: '学生行为准则',
					component: DtStudentQuantitation
				},
				{
					path: '/dtstudentother',
					name: '其他',
					component: DtStudentOther
				},
			]
		},
		{
			path: '/dtindex',
			name: '宿舍管理',
			component: DtIndex,
			iconClass: "el-icon-menu",
			children: [{
					path: '/dtdormitoryinfo',
					name: '宿舍楼信息',
					component: DtDormitoryInfo
				},
				{
					path: '/dtdormitoryroominfo',
					name: '宿舍房间信息',
					component: DtDormitoryRoomInfo
				},
				{
					path: '/dtdormitoryroombedinfo',
					name: '宿舍房间床位信息',
					component: DtDormitoryRoomBedInfo
				},
			]
		},
		{
			path: '/dtindex',
			name: '德育考核',
			component: DtIndex,
			iconClass: "el-icon-menu",
			children: [{
				path: '/dtassessmentrecord',
				name: '德育考核记录',
				component: DtAssessmentRecord
			}, ]
		},
		{
			path: '/dtindex',
			name: '其他',
			component: DtIndex,
			iconClass: "el-icon-menu",
			children: [{
					path: '/dtmoralquantitation',
					name: '德育考核细则',
					component: DtMoralQuantitation
				},
				{
					path: '/dtclass',
					name: '班级',
					component: DtClass
				},
				{
					path: '/dtposition',
					name: '职位',
					component: DtPosition
				},
			]
		},
	]
})
router.beforeEach((to, from, next) => {
	if (to.path === '/'||to.path === '/dtcountdown') {
		next();
	} else {
		// 从本地存储里获取token
		let token = localStorage.getItem('token');
		// 判断token是否为空如果为空则跳转到登录页 如果有则放行
		if (token === null || token === '') {
			next({
				path: '/'
			});
		} else {
			next();
		}
	}
})
export default router