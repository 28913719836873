<template>
	<div>
		<div class="flex">
			<div class="flex_item">
				<el-input style="width: 200px;" v-model="name" placeholder="输入关键词"></el-input>
				<el-button type="primary" @click="search">搜索</el-button>
			</div>
			<div class="flex_item">
				<el-button type="primary" @click="add">新增</el-button>
				<el-button type="success" @click="edit">编辑</el-button>
				<el-button type="danger" @click="delete_all">删除</el-button>
			</div>
		</div>
		<TableList :tableHeader="tableHeader" :tableData="tableData" @selectTableRows="selectTableRows"></TableList>
		<PageNation :pageTotle="page_totle" @currentPage='currentPage'></PageNation>
		<UploadFile downloadUrl="/admin/moralquantitationc/excel_export"
			uploadUrl="/admin/moralquantitationc/insert_excel"></UploadFile>
	</div>
</template>

<script>
	import UploadFile from '../components/UploadFile.vue'
	import PageNation from '../components/PageNation.vue'
	import TableList from '../components/TableList.vue'
	import {
		getData
	} from "@/utils/table"
	export default {
		name: 'DtMoralQuantitation',
		components: {
			UploadFile,
			PageNation,
			TableList
		},
		data() {
			return {
				loadingTableData: true,
				formLabelWidth: '110px',
				tableHeader: [{
						fixed: true,
						prop: 'id',
						label: 'id',
						width: 100
					},
					{
						fixed: false,
						prop: 'category',
						label: '类型',
						width: 150
					},
					{
						fixed: false,
						prop: 'number',
						label: '序号',
						width: 100
					},
					{
						fixed: false,
						prop: 'content',
						label: '具体内筒',
						width: null
					},
					{
						fixed: false,
						prop: 'score',
						label: '分值',
						width: 100
					}
				],
				tableData: [],
				name: "",
				searchData:false,
				page_totle: 0,
			}
		},
		created() {
			getData(this, "/admin/moralquantitationc/index", {
				page: 1
			}, (data) => {
				this.page_totle = data[0]
				this.tableData = data[1]
			})
		},
		methods: {
			selectTableRows(val) {
				this.select_rows = val
			},
			search() {
				if (this.name === "") {
					this.$message({
						message: '请输入关键词',
						type: 'warning'
					})
				} else {
					this.searchData=true
					getData(this, '/admin/moralquantitationc/search', {
						name: this.name,
						page:1
					},data=>{
						this.page_totle = data[0]
						this.tableData = data[1]
					})
				}
			},
			add_modle() {
				this.addVisible = true;
			},
			add: function() {
				if (this.add_student.code == "" || this.add_student.name == "") {
					alert("请完善数据");
				} else {
					this.axios.post(this.host + "/index.php/admin/student/add", {
						code: this.add_student.code,
						name: this.add_student.name,
						period: this.add_student.period,
					}).then(respon => {
						if (respon.data === 'success') {
							this.$message({
								message: '添加成功',
								type: 'success'
							})
							this.addVisible = false
							this.get_data()
						} else {
							this.$message({
								message: '添加失败',
								type: 'warning'
							})
						}
					}, err => {
						if (err.response.data.code === 401) {
							this.$router.push({
								name: 'DtLogin'
							})
						}
					});

				}
			},
			edit() {
				if (this.select_val.length < 1) {
					this.$message({
						message: '请选择一条数据！',
						type: 'warning'
					})
				} else {
					if (this.select_val.length == 1) {
						this.edit_student = this.select_val[0]
						var arr = [this.edit_student.level_id, this.edit_student.grade_id, this.edit_student.class_id]
						this.level_grade_class_value = arr
						this.editVisible = true
					} else {
						this.$message({
							message: '只能选择一条数据！',
							type: 'warning'
						})
					}
				}
			},
			post_edit() {
				this.axios.post(this.host + "/index.php/admin/student/edit", this.edit_student).then(respon => {
					if (respon.data === 'success') {
						this.$message({
							message: '修改成功',
							type: 'success'
						})
						this.editVisible = false;
					} else {
						this.$message({
							message: '修改失败',
							type: 'warning'
						})
					}
				}, err => {
					if (err.response.data.code === 401) {
						this.$router.push({
							name: 'DtLogin'
						})
					}
				});
			},
			delete_all() {
				if (this.codes.length == 0) {
					alert("请选择数据");
				} else {
					let arr = []
					for (var i = 0; i < this.select_val.length; i++) {
						arr.push(this.select_val[i].name)
					}
					this.$confirm("请确认是否删除数据:" + arr.toString(), '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.axios.post(this.host + "/index.php/admin/student/delete", {
							codes: this.codes.join(",")
						}).then(respon => {
							if (respon.data == "success") {
								for (var k = 0; k < this.codes.length; k++) {
									for (var i = 0; i < this.tableData.length; i++) {
										if (this.tableData[i].code == this.codes[k]) {
											this.tableData.splice(i, 1);
										}
									}
								}
								this.$message({
									type: 'success',
									message: '删除成功!'
								});
							} else {
								this.$message({
									type: 'warn',
									message: '删除失败'
								});
							}
						}, err => {
							if (err.response.data.code === 401) {
								this.$router.push({
									name: 'DtLogin'
								})
							}
						});
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});
					});
				}
			},

			//翻页处理
			currentPage(val) {
				getData(this, "/admin/moralquantitationc/index", {
					page: val
				}, (data) => {
					this.page_totle = data[0]
					this.tableData = data[1]
				})
			},
			handleChange(value) {
				this.edit_student.level_id = value[0]
				this.edit_student.grade_id = value[1]
				this.edit_student.class_id = value[2]
			}
		}
	}
</script>

<style>
</style>