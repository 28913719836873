<template>
	<div style="margin-bottom: 20px;">
		<el-pagination @current-change="handleCurrentChange" background layout="total, prev, pager, next"
			:total="pageTotle">
		</el-pagination>
	</div>
</template>

<script>
	export default {
		props: ['pageTotle'],
		data() {
			return {
				loading: false,
				fileList: [],
			}
		},
		methods: {
			//翻页处理
			handleCurrentChange(val) {
				this.$emit('currentPage', val)
			},
		}
	}
</script>

<style>
</style>