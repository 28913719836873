export function getData(root, url, parmas,callback) {
	root.service.post(url, parmas).then(respon => {
		callback(respon.data)
	}, err => {
		if (err.response.status === 401) {
			root.$router.push({
				path: '/'
			})
		}
	})
}
export function del(root, url, title, params, callback) {
	root.$confirm("请确认是否删除数据:" + title, '提示', {
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning'
	}).then(() => {
		root.service.post(url, params).then(respon => {
			if (respon.data == "success") {
				callback()
			} else {
				alert("删除失败！")
			}
		}, err => {
			if (err.response.status === 401) {
				root.$router.push({
					path: '/'
				})
			}
		})
	}).catch(() => {
		this.$message({
			type: 'info',
			message: '已取消删除'
		})
	});
}
export function formUpload(root, url, form, callback) {
	root.service.post(url, form).then(respon => {
		if (respon.data.status === 'success') {
			root.$message({
				message: respon.data.message,
				type: 'success'
			})
			callback()
		} else {
			root.$message({
				message: respon.data.message,
				type: 'warning'
			})
		}
	}, err => {
		if (err.response.status === 401) {
			root.$router.push({
				path: '/'
			})
		}
	})
}