<template>
	<div>
		<el-dialog :title="formInfoSelf.title" :visible.sync="formInfoSelf.visible">
			<el-form :model="formObjectSelf" label-width="100px" :rules="formInfoSelf.rules" ref="formRules">
				<template v-for="(item,index) in formInfoSelf.formItems">
					<el-form-item :label="item.label" :key='index' v-if="item.type=='input'" :prop="item.key">
						<el-input v-model="formObjectSelf[item.key]"></el-input>
					</el-form-item>
					<!-- 单选 -->
					<el-form-item :label="item.label" :key='index' v-else-if="item.type=='radio'" :prop="item.key">
						<el-radio-group v-model="formObjectSelf[item.key]">
							<el-radio :label="option.id" v-for="(option,option_index) in item.options"
								:key="option_index">{{option.campus_name}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<!-- 级联多选 -->
					<el-form-item :label="item.label" :key='index' v-else-if="item.type=='cascader'" :prop="item.key">
						<el-cascader v-model="formObjectSelf[item.key]" :options="item.options"></el-cascader>
					</el-form-item>
					<!-- 日期选择器 -->
					<el-form-item :label="item.label" :key='index' v-else-if="item.type=='date'" :prop="item.key">
						<el-date-picker value-format="yyyy-MM-dd" type="date" placeholder="选择日期"
							v-model="formObjectSelf[item.key]"></el-date-picker>
					</el-form-item>
				</template>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="formInfoSelf.visible = false">取 消</el-button>
				<el-button type="primary" @click="upLoad">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		formUpload
	} from '../utils/table.js'
	export default {
		props: ['formObject', 'formInfo'],
		data() {
			return {
				formInfoSelf: this.formInfo,
				formObjectSelf: this.formObject,
				loading: false
			}
		},
		watch: {
			formObject: function(val) {
				this.formObjectSelf = val
			},
			formInfo: function(val) {
				this.formInfoSelf = val
			}
		},
		methods: {
			upLoad() {
				this.$refs['formRules'].validate((valid) => {
					if (valid) {
						if (this.formInfoSelf.type === 'download') {
							//下载文件
							this.loading = true
							this.service.post(this.formInfoSelf.url, this.formObjectSelf, {
								responseType: 'blob'
							}).then(respon => {
								this.formInfoSelf.visible = false
								let start_index = respon.headers['content-disposition'].indexOf("=")
								let end_index = respon.headers['content-disposition'].indexOf(".xlsx")
								let urlencode = respon.headers['content-disposition'].substring(
									start_index + 1, end_index)
								let title = decodeURIComponent(urlencode)
								let blob = new Blob([respon.data], {
									type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
								}); // 为blob设置文件类型，这里以.xlsx为例
								let url = window.URL.createObjectURL(blob); // 创建一个临时的url指向blob对象
								let a = document.createElement("a");
								a.href = url;
								a.download = title;
								a.click();
								// 释放这个临时的对象url
								window.URL.revokeObjectURL(url);
								this.loading = false
							}, err => {
								if (err.response.status === 401) {
									this.$router.push({
										path: '/'
									})
								}
							})
						} else {
							// console.log(this.formObjectSelf)
							// 上传数据
							formUpload(this, this.formInfoSelf.url, this.formObjectSelf, () => {
								this.formInfoSelf.visible = false
							})
						}
					} else {
						return false
					}
				})
			}

		},
	}
</script>

<style>
</style>