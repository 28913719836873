<template>
	<div>
		<FormDialog :formObject="formObject" :formInfo="formInfo"></FormDialog>
		<div class="flex">
			<div class="flex_item">
				<el-input style="width: 200px;" v-model="name" placeholder="输入姓名搜索"></el-input>
				<el-button type="primary" @click="search">搜索</el-button>
			</div>
			<div class="flex_item">
				<el-button type="primary" @click="add">新增</el-button>
				<el-button type="success" @click="edit">编辑</el-button>
				<el-button type="danger" @click="delete_all">删除</el-button>
			</div>
		</div>
		<TableList :tableHeader="tableHeader" :tableData="tableData" @selectTableRows="selectTableRows"></TableList>
		<PageNation :pageTotle="page_totle" @currentPage='currentPage'></PageNation>
		<UploadFile @uploaded="uploaded" downloadUrl="/admin/teacherclass/excel_export" uploadUrl="/admin/teacherclass/insert_excel"></UploadFile>
	</div>
</template>

<script>
	import UploadFile from '../components/UploadFile.vue'
	import PageNation from '../components/PageNation.vue'
	import TableList from '../components/TableList.vue'
	import FormDialog from '../components/FormDialog.vue'
	import {
		getData,
		del
	} from "@/utils/table"
	export default {
		name: 'DtTeacher',
		components: {
			UploadFile,
			PageNation,
			TableList,
			FormDialog
		},
		data() {
			return {
				formObject: {},
				formInfo: {},
				tableHeader: [{
						fixed: true,
						prop: 'id',
						label: '编号',
						width: 100
					},
					{
						fixed: false,
						prop: 'teacher_info_id',
						label: '教师编号',
						width: 150
					},
					{
						fixed: false,
						prop: 'name',
						label: '姓名',
						width: 150
					},
					{
						fixed: false,
						prop: 'tele',
						label: '电话',
						width: 120
					},
					{
						fixed: false,
						prop: 'school_name',
						label: '学校',
						width: 200
					},
					{
						fixed: false,
						prop: 'campus_name',
						label: '分校',
						width: 200
					},
					{
						fixed: false,
						prop: 'period',
						label: '学级',
						width: null
					},
					{
						fixed: false,
						prop: 'level_name',
						label: '学部',
						width: null
					},
					{
						fixed: false,
						prop: 'grade_name',
						label: '年级',
						width: null
					},
					{
						fixed: false,
						prop: 'class_number',
						label: '班级',
						width: null
					},
					{
						fixed: false,
						prop: 'headmaster_text',
						label: '班主任',
						width: null
					},
				],
				tableData: [],
				name: "",
				searchData: false,
				page_totle: 1,
				ids: [],
				tableRows: []
			}
		},
		created() {
			getData(this, "/admin/teacherclass/index", {
				page: 1
			}, data => {
				this.page_totle = data[0]
				this.tableData = data[1]
				for(var i=0;i<this.tableData.length;i++){
					if(this.tableData[i].headmaster==1){
						this.tableData[i].headmaster_text='是'
					}else{
						this.tableData[i].headmaster_text='否'
					}
				}
			})
		},
		methods: {
			//搜索数据
			search() {
				
			},
			//添加数据
			add() {
				
			},
			//编辑数据
			edit() {
				if (this.tableRows.length < 1) {
					this.$message({
						message: '请选择一条数据！',
						type: 'warning'
					})
				} else {
					if (this.tableRows.length == 1) {
						this.formObject = {
							...this.tableRows[0]
						}
						this.formInfo.title = '编辑教师信息'
						this.formInfo.visible = true
						this.formInfo.url = '/admin/teacher/edit'
					} else {
						this.$message({
							message: '只能选择一条数据！',
							type: 'warning'
						})
					}
				}
			},
			//删除数据
			delete_all() {
				if (this.tableRows.length == 0) {
					this.$message({
						message: '选择数据',
						type: 'warning'
					})
				} else {
					let arr = []
					let ids = []
					for (var i = 0; i < this.tableRows.length; i++) {
						arr.push(this.tableRows[i].name)
						ids.push(this.tableRows[i].id)
					}
					del(this, "/admin/teacher/delete", arr.toString(), {
						ids: ids.join(",")
					}, () => {
						for (var k = 0; k < ids.length; k++) {
							for (var i = 0; i < this.tableData.length; i++) {
								if (this.tableData[i].id == ids[k]) {
									this.tableData.splice(i, 1);
								}
							}
						}
					})
				}
			},
			//表格选择处理，选出每条数据的编码
			selectTableRows(tableRows) {
				this.tableRows = tableRows;
			},
			//翻页处理
			currentPage(val) {
				if (this.searchData) {
					getData(this, '/admin/teacher/search', {
						name: this.name,
						page: 1
					}, data => {
						this.page_totle = data[0]
						this.tableData = data[1]
					})
				} else {
					getData(this, "/admin/teacher/index", {
						page: val
					}, data => {
						this.page_totle = data[0]
						this.tableData = data[1]
					})
				}

			},
			uploaded(){
				getData(this, "/admin/teacherclass/index", {
					page: 1
				}, data => {
					this.page_totle = data[0]
					this.tableData = data[1]
					for(var i=0;i<this.tableData.length;i++){
						if(this.tableData[i].headmaster==1){
							this.tableData[i].headmaster_text='是'
						}else{
							this.tableData[i].headmaster_text='否'
						}
					}
				})
			}
		}
	}
</script>
<style lang="scss">
	.flex {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 10px;
	}
</style>