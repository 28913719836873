<template>
	<div>
		<div class="flex">
			<div class="flex_item">
				<el-input style="width: 200px;" v-model="name" placeholder="输入姓名搜索"></el-input>
				<el-button type="primary" @click="search">搜索</el-button>
			</div>
			<div class="flex_item">
			</div>
		</div>
		<TableList :tableHeader="tableHeader" :tableData="tableData" @selectTableRows="selectTableRows"></TableList>
		<PageNation :pageTotle="page_totle" @currentPage='currentPage'></PageNation>
		<el-button type="danger" @click="add_grade">自动刷新班级</el-button>
		<UploadFile downloadUrl="/admin/classinfo/excel_export" uploadUrl="/admin/classinfo/insert_excel"></UploadFile>
	</div>
</template>

<script>
	import UploadFile from '../components/UploadFile.vue'
	import PageNation from '../components/PageNation.vue'
	import TableList from '../components/TableList.vue'
	import {
		getData
	} from "@/utils/table"
	export default {
		name: 'DtTeacher',
		components: {
			UploadFile,
			PageNation,
			TableList
		},
		data() {
			return {
				formLabelWidth: '110px',
				tableHeader: [{
						fixed: true,
						prop: 'id',
						label: '班级id',
						width: 100
					},
					{
						fixed: false,
						prop: 'school_name',
						label: '学校',
						width: null
					},
					{
						fixed: false,
						prop: 'campus_name',
						label: '分校',
						width: 200
					},
					{
						fixed: false,
						prop: 'period',
						label: '学级',
						width: 100
					},
					{
						fixed: false,
						prop: 'level_name',
						label: '学部',
						width: 100
					},
					{
						fixed: false,
						prop: 'grade_name',
						label: '年级',
						width: 150
					},
					{
						fixed: false,
						prop: 'class_number',
						label: '班级',
						width: 100
					},
					{
						fixed: false,
						prop: 'status_text',
						label: '状态',
						width: 100
					},
				],
				tableData: [],
				name: "",
				page_totle: 1,
				codes: [],
				select_val: [],
				test:{
					name:'zengqingqing',
					age:'13',
					heigt:170
				}
			}
		},
		created() {
			var key='name'
			console.log(this.test[key])
			getData(this, "/admin/classinfo/index", {
				page: 1
			},data=>{
				console.log(data)
				this.page_totle = data[0]
				this.tableData = data[1]
				for(var i=0;i<this.tableData.length;i++){
					if(this.tableData[i].status==0){
						this.tableData[i].status_text="正常"
					}else{
						this.tableData[i].status_text="已毕业"
					}
				}
			})
		},
		methods: {
			edit() {
				if (this.select_val.length < 1) {
					this.$message({
						message: '请选择一条数据！',
						type: 'warning'
					})
				} else {
					if (this.select_val.length == 1) {
						this.edit_teacher = this.select_val[0];
						this.editVisible = true;
					} else {
						this.$message({
							message: '只能选择一条数据！',
							type: 'warning'
						})
					}
				}
			},
			post_edit() {

			},
			delete_all() {

			},
			//表格选择处理，选出每条数据的编码
			handleSelectionChange(val) {
				this.select_val = val;
				let that = this;
				this.codes = [];
				val.forEach(function(item) {
					that.codes.push(item.code);
				});
			},
			//翻页处理
			currentPage(val){
				getData(this, "/admin/classinfo/index", {
					page: val
				},(data)=>{
				this.page_totle = data[0]
				this.tableData = data[1]
				for(var i=0;i<this.tableData.length;i++){
					if(this.tableData[i].status==0){
						this.tableData[i].status_text="正常"
					}else{
						this.tableData[i].status_text="已毕业"
					}
				}
			})
			},
			add_grade() {
				this.service.post('/admin/classinfo/add_grade',{school_id:localStorage.school_id}).then(respon => {
					if(respon.data==='success'){
						this.$message({
							message: '自动刷新完成！',
							type: 'success'
						})
					}
					}, err => {
						alert(err.message);
					}
				)
			}
		}
	}
</script>

<style lang="scss">
	.flex{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 10px;
	}
</style>