import axios from 'axios'
import token from "@/utils/token.js"
const service=axios.create({
	baseURL:"/dt6/public/index.php",// /api /dt6/public/index.php
	timeout:1000*60
})
//请求拦截器
service.interceptors.request.use((config)=>{
	config.headers['token']=token.getToken('token')
	return config
},(error)=>{
	return Promise.reject(error)
})
//添加响应拦截器
service.interceptors.response.use((response)=>{
	//添加响应操作
	let {status}=response
	if(status!=200){
		alert("网络访问不成功")
	}
	return response
},(error)=>{
	return Promise.reject(error)
})
export default service
